import Vue from 'vue'
import VueRouter from 'vue-router'



const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [

  {
    path: '/:mid',
    name: 'menu',
    component:  () => import('../views/menu')
  },
  {
    path: '/menu/:mid/detail/:id',
    name: 'detail',
    component:  () => import('../views/menu/detail')
  },
  {
    path: '/checkout/:mid',
    name: 'checkout',
    component:  () => import('../views/checkout'),
    meta:{
      auth: true
    }
  },
  {
    path: '/profile/:mid',
    name: 'profile',
    component:  () => import('../views/profile')
  },
  {
    path: '/orders/:mid',
    name: 'orders',
    component:  () => import('../views/profile/orders'),
    meta:{
      auth: true
    }
  },
  {
    path: '/order/:id/:mid',
    name: 'order',
    component:  () => import('../views/profile/order'),
    meta:{
      auth: true
    }
  },
  {
    path: '/login/:mid',
    name: 'login',
    component:  () => import('../views/user/login')
  },
  {
    path: '/register/:mid',
    name: 'register',
    component:  () => import('../views/user/register')
  },
  {
    path: '/reset-password/:mid',
    name: 'reset',
    component:  () => import('../views/user/reset')
  },
  {
    path: '/privacy/:mid',
    name: 'privacy',
    component:  () => import('../views/profile/privacy')
  },
  {
    path: '/shop/:mid',
    name: 'shop',
    component:  () => import('../views/profile/shop')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to,from,next)=>{
  
  let loged = localStorage.getItem('loged')

  if(to.meta.auth){
    if(loged){
      return next()
    }else{
      return  next({ name: 'login', params:{mid:from.params.mid} }) 
    }
  }else{
      next()
  }
})

export default router
