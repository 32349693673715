import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cart:[]
  },
  getters: {
    cartCount(state){
      return state.cart.reduce((sum, dish) => {
          return parseInt(sum) + parseInt(dish.quantity)
      },0);
    },
    subtotal(state){
      return state.cart.reduce((sum, dish) => {
        return parseFloat(sum) + parseFloat(dish.amount)
      },0).toFixed(2)
    },
    cart(state){
      return state.cart
    }

  },
  mutations: {
    updateCart(state, cart){
      state.cart = cart
    }
  },
  actions: {
  },
  modules: {
  }
})
