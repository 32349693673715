<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
  export default{
    created(){
      window.localStorage.removeItem('user')
    },
    methods:{
      // checkUser(){
      //   const user = localStorage.getItem('user')
      //   if(user){
      //     localStorage.removeItem('user')
      //   } 
      // }
    }
  }
</script>

<style>
html, body{
  height: 100%;
  width: 100%;
}
  
#app{
  font-family: Robot, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  color: #323233;
}
.container-max-576{
  max-width: 578px;
  height: 100%;
  margin: 0 auto;
  border-left:1px solid #f5f6f7;
  border-right:1px solid #f5f6f7;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

@media screen and (max-width: 576px){
  .container-max-576{
    border: unset;
  }
}
  
</style>
